body, html, #root{
    height: 100%;
    background-color: #5fff14;
    overflow-y: hidden;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
#backarrow {
    float:left;
    margin: 1vh 1vh 1vh 1vh;
}
.card-text{
    margin: 4vh;
}

#main {
    height: 100%;
    text-align: center;
    font-size: 2.5vh;
}

@font-face {
    font-family: 'OdibeeSans';
    src: local('OdibeeSans'), url("./fonts/Odibee_Sans/OdibeeSans-Regular.ttf") format('truetype');
}
@font-face {
    font-family: 'NanumRegular';
    src: local('NanumRegular'), url("./fonts/Nanum_Gothic_Coding/NanumGothicCoding-Regular.ttf") format('truetype');
}


/*OBJECTS*/
.col{
    box-sizing: border-box;
    float: left;
    width: 50%;
    height: 100%;
}

.card{
    border: solid 0.3vh #5fff14;
}
.card-static{
    border: solid 0.3vh #5fff14;height: 100%;
    /*background-color: #DADED4;*/
    background-color: #202020;
    color:#DADED4;
    font-family: NanumRegular;

}

.card-front:hover{
    transform: rotateY(180deg);
    -webkit-transform: rotatey(180deg);
    -ms-transform: rotatey(180deg);
    transition-duration: 1s;
}
.card-back{
    transform: rotateY(360deg);
    -webkit-transform: rotatey(360deg);
    -ms-transform: rotatey(360deg);
    transition-duration: 1s;
}

.card-front{
    height: 100%;
    /*background-color: #DADED4;*/
    background-color: #202020;
    color:#DADED4;
    font-family: NanumRegular;
    position: relative;
}
.card-front>div{
    position: absolute;
    font-size: xxx-large;
    top: 45%;
    width: 100%;
}

.card-back{
    height: 100%;
    border: solid 0.2vh;
    /*background-color: #DADED4;*/
    background-color: #DADED4;
    color:#3F3F3F;
}



/*contattami*/
#contattami-main{
    height: 50%;
}



/*cosa faccio*/
#cosafaccio-main{
    height: 49vh;
}

/*chi sono*/
#chisono-main{
    height: 66%;

}
#chisono-main .card-text, #cosafaccio-main .card-text{
    text-align: justify;
}

#meImg{
    width: 35%;
}

/*saluto*/
#saluto-main{
height: 33%;
}

.descr{
    font-size: 0.5em;
}

.my-img-1{
    position: fixed;
    top: 25vh;
    left: -7vw;
    width: 40vw;
}

.my-img-2{
    position: fixed;
    top: 27vh;
    right: -10vw;
    width: 40vw;
}


/*
colori:
Tanly: #DADED4
Light Green: #9DC88D
Blackish: #202020
Dark Grey: #3F3F3F
Medium Grey: #707070
White: #FFFFFF

Olive: #A3BCB6
Green Leaf: #39603D
*/

@media only screen and (max-width: 768px){
    .card-text{
        margin: 2vh;
        margin-top: 35px;
    }
    .card-static>div{
        margin: 1vh;
    }
    .card-front>div{
        position: absolute;
        font-size: large;
        top: 45%;
        width: 100%;
        font-weight: bolder;
    }

    #main {
        height: 100%;

        font-size: 2vh;
    }

    p{
        word-wrap:break-word;
    }
    li{
        text-align: center;
    }

    .card-back{
        overflow-x: scroll;
        text-align: left;

    }

    #meImg{
        width: 100%;
    }
}